<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  mounted() {
    this.$router.push('/Home');
  }
};
</script>
<style lang="scss">
</style>
