import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/Home',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/PersonalDetails',
    name: 'PersonalDetails',
    component: () => import('../views/PersonalDetails.vue')
  },
  {
    path: '/MyMpf',
    name: 'MyMpf',
    component: () => import('../views/MyMpf.vue')
  }
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
});

export default router;
